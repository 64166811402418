<template>
  <page-layout
    :breadcrumbs="[
      {
        text: 'HOME',
        disabled: false,
        exact: true,
        to: '/'
      },
      {
        text: 'FAQs',
        disabled: true,
        exact: true
      }
    ]"
  >
    <v-container>
      <CabeceraYTablas :tipo="'FAQs'">
        <v-data-table
          :headers="headers"
          :items="faqs"
          item-key="id"
          :loading="loading"
          class="elevation-1"
          :options.sync="pagination"
          :server-items-length="totalFAQs"
          :footer-props="{
            'items-per-page-text': 'FAQs por pagina',
            pageText: '{0}-{1} de {2}',
            'items-per-page-options': [25, 50, 100]
          }"
        >
          <template v-slot:top>
            <v-row align="end" justify="space-between" class="mx-4">
              <v-col cols="6">
                <v-text-field v-model="search" @blur="loadFAQs()" label="Buscar" class="mx-4"></v-text-field
              ></v-col>
              <v-col>
                <v-autocomplete
                  :items="promociones"
                  clearable
                  label="Promoción"
                  v-model="promocionSeleccionada"
                  item-text="promotion_desc"
                  item-value="promotion_code"
                ></v-autocomplete>
              </v-col>
              <v-col cols="auto" justify="space-between" align="end">
                <v-btn class="primary" @click="abrirCrear()">Nueva FAQ</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.title="{ item }">
            <td style="width: 600px;">{{ item.title }}</td>
          </template>
          <template v-slot:no-data>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:no-results>
            <span>No se ha encontrado ningún elemento coincidente con la busqueda</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="min-width: 125px;">
              <v-icon class="primary--text mr-2" @click="abrirVer(item)">
                {{ 'mdi-eye' }}
              </v-icon>
              <v-icon class="primary--text mr-2" @click="abrirEditar(item)">
                {{ 'mdi-pencil' }}
              </v-icon>
              <v-icon class="primary--text iconAction" @click.stop="borrar(item)">mdi-delete</v-icon>
            </div>
          </template>
        </v-data-table>
      </CabeceraYTablas>
    </v-container>
    <v-dialog v-if="abrirFAQ" v-model="abrirFAQ" width="80%">
      <FAQ
        :faq="faq"
        :ver="viendo"
        :accion="accion"
        @editar="guardarFAQ($event, false)"
        @crear="guardarFAQ($event, true)"
        @cancelar="cancelarEditar()"
      />
    </v-dialog>
    <v-dialog v-if="abrirPopUpBorrar" v-model="abrirPopUpBorrar" width="600">
      <PopUpEliminar
        @confirmar="borrarFAQ()"
        @cancelar="cancelarEditar()"
        :nombre="'Pregunta frecuente'"
        :tipo="'FAQ'"
        :masculino="false"
      />
    </v-dialog>
  </page-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CabeceraYTablas from '@/components/generic/CabeceraYTablas.vue'
import PopUpEliminar from '@/components/popUps/PopUpEliminar'
import FAQ from '@/pages/FAQ.vue'
export default {
  name: 'GestionPreguntas',
  components: {
    CabeceraYTablas,
    PopUpEliminar,
    FAQ
  },
  props: [],
  data() {
    return {
      loading: false,
      abrirFAQ: false,
      search: '',
      abrirPopUpBorrar: false,
      faqBorrar: null,
      faq: null,
      promocionSeleccionada: null,
      faqs: [],
      viendo: false,
      searchFields: ['title'],
      totalFAQs: 0,
      inicializado: false,
      accion: null,
      promociones: null,
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['title'],
        sortDesc: [true, false],
        multiSort: true
      }
    }
  },
  async created() {
    this.loading = true
    this.promociones = await this.loadPromotions()
    await this.loadFAQs()
    this.loading = false
    this.inicializado = true
  },
  computed: {
    ...mapGetters({
      user: 'login/user'
    }),
    headers() {
      return [
        {
          text: 'Título',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        {
          text: 'Promoción',
          align: 'start',
          sortable: true,
          value: 'promotion_desc'
        },
        {
          text: 'Categoría',
          align: 'start',
          sortable: true,
          value: 'category.text'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getFAQsBack: 'faqs/getFAQs',
      loadPromotions: 'promotions/getPromotions',
      updateFAQ: 'faqs/updateFAQ',
      deleteFAQ: 'faqs/deleteFAQ',
      addAlert: 'alerts/add'
    }),
    borrar(item) {
      this.abrirPopUpBorrar = true
      this.faqBorrar = item
    },
    abrirVer(item) {
      this.accion = 'ver'
      this.abrirFAQ = true
      this.faq = item
      this.viendo = true
    },
    abrirEditar(item) {
      this.accion = 'editar'
      this.abrirFAQ = true
      this.faq = item
      this.viendo = false
    },
    abrirCrear() {
      this.accion = 'crear'
      this.viendo = false
      this.abrirFAQ = true
      this.faq = null
    },
    cancelarEditar() {
      this.viendo = false
      this.accion = ''
      this.abrirFAQ = false
      this.abrirPopUpBorrar = false
      this.faq = null
    },
    async guardarFAQ(ev, crear) {
      try {
        await this.updateFAQ({ faq: ev })
        this.addAlert({
          text: 'La FAQ se ha ' + (crear ? 'creado' : 'editado') + ' correctamente',
          color: 'success'
        })
        this.accion = ''
        this.loading = true
        await this.loadFAQs()
        this.abrirFAQ = false
      } catch (error) {
        this.loading = false
        this.abrirFAQ = false
        return error
      }
    },
    async borrarFAQ() {
      try {
        await this.deleteFAQ({ faq: this.faqBorrar })
        this.addAlert({ text: 'La FAQ se ha eliminado correctamente', color: 'success' })
        this.accion = ''
        this.loading = true
        await this.loadFAQs()
        this.abrirPopUpBorrar = false
        this.abrirFAQ = false
      } catch (error) {
        this.loading = false
        this.abrirPopUpBorrar = false
        this.abrirFAQ = false
        return error
      }
    },
    async loadFAQs() {
      this.loading = true
      const data = await this.getFAQsBack({
        filtros: {
          promocion: this.promocionSeleccionada,
          search: this.search !== null ? this.search : null,
          searchFields: this.searchFields,
          ...this.pagination
        }
      })
      this.faqs = data.items
      this.totalFAQs = data.totalItems
      this.loading = false
    }
  },
  watch: {
    pagination: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadFAQs()
        }
      }
    },
    promocionSeleccionada: {
      deep: true,
      async handler() {
        if (this.inicializado) {
          await this.loadFAQs()
        }
      }
    }
  }
}
</script>
<style lang="sass">
.newSelect .v-select__selections
  max-height: 32px
  flex-flow: nowrap
  text-overflow: ellipsis
</style>
