<template>
  <div>
    <PopUpEditar
      nombre="FAQ"
      :accion="accion"
      :tipo="'FAQ'"
      :masculino="false"
      @enviarEditar="enviarEditar()"
      @enviarCrear="enviarCrear()"
      @cancelar="
        $emit('cancelar')
        resetValues()
      "
    >
      <v-container fluid>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="6" class="centeredInput">
              <v-text-field
                v-if="!ver"
                dense
                :readonly="ver"
                label="Título"
                :style="ver ? 'pointer-events:none' : ''"
                :rules="[rules.required, rules.max(100)]"
                v-model="faqEditar.title"
              ></v-text-field>
              <h2 v-else>{{ faqEditar.title }}</h2>
            </v-col>
            <v-col style="height: 100%;">
              <v-autocomplete
                :items="promociones"
                label="Promoción"
                :readonly="ver"
                v-model="promocionSeleccionada"
                :rules="[rules.required]"
                return-object
                item-text="promotion_desc"
                item-value="promotion_code"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" style="height: 100%;">
              <v-autocomplete
                :items="categorias"
                label="Categoría"
                :readonly="ver"
                :disabled="ver"
                v-model="faqEditar.category_id"
                item-text="text"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col cols="12" :class="[ver ? 'noMostrar' : '']">
              <v-btn color="primary" @click="pegarTextoPlano()">
                PEGAR TEXTO PLANO
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="space-between" dense>
            <v-col cols="12" :class="[ver ? 'noMostrar' : '']">
              <wysiwyg
                v-model="faqEditar.text"
                :readonly="ver"
                label="Texto"
                :style="ver ? 'pointer-events:none' : ''"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </PopUpEditar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PopUpEditar from '@/components/generic/PopUpEditar.vue'
import rulesMixin from '@/mixins/rules'
export default {
  name: 'FAQ',
  mixins: [rulesMixin],
  components: {
    PopUpEditar
  },
  props: ['faq', 'ver', 'accion'],
  data() {
    return {
      faqEditar: {
        title: null,
        summary: null,
        text: null
      },
      valid: false,
      promocionSeleccionada: null,
      promociones: null,
      categorias: null,
      categoriaSeleccionada: null,
      modal: false
    }
  },
  async created() {
    this.promociones = await this.loadPromotions()
    this.categorias = await this.loadCategorias()
    if (this.faq != null) {
      this.faqEditar = JSON.parse(JSON.stringify(this.faq))
      this.promocionSeleccionada = this.promociones.find(x => x.promotion_code == this.faq.promotion_code)
    }
  },
  computed: {
    ...mapGetters({}),
    cambiado() {
      return this.faq != null && JSON.stringify(this.faq) != JSON.stringify(this.faqEditar)
    },
    faqEnviar() {
      let copy = JSON.stringify(this.faqEditar)
      copy = JSON.parse(copy)
      let text = this.quitarFormato(this.faqEditar.text)
      copy.text = this.ponerFormato(text)
      return copy
    }
  },
  methods: {
    ...mapActions({
      addAlert: 'alerts/add',
      loadPromotions: 'promotions/getPromotions',
      loadCategorias: 'faqs/getCategories'
    }),
    async pegarTextoPlano() {
      try {
        const text = await navigator.clipboard.readText()
        if (text == null || text == '') {
          this.addAlert({ text: 'No tiene nada copiado en el portapapeles', color: 'warning' })
          return
        } else {
          if (this.faqEditar.text != null) {
            this.faqEditar.text += this.quitarFormato(text)
          } else {
            this.faqEditar.text = this.quitarFormato(text)
          }
        }
      } catch (err) {
        console.error('Error al leer el portapapeles:', err)
      }
    },
    ponerFormato(texto) {
      return texto
        ? texto
            .replaceAll('[b]', '<b>')
            .replaceAll('[i]', '<i>')
            .replaceAll('[u]', '<u>')
            .replaceAll('[/b]', '</b>')
            .replaceAll('[/i]', '</i>')
            .replaceAll('[/u]', '</u>')
        : null
    },
    quitarFormato(texto) {
      return texto
        ? texto
            .replaceAll('<b>', '[b]')
            .replaceAll('<i>', '[i]')
            .replaceAll('<u>', '[u]')
            .replaceAll('</b>', '[/b]')
            .replaceAll('</i>', '[/i]')
            .replaceAll('</u>', '[/u]')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('<div>', '')
            .replaceAll('</div>', '\n')
            .replace(/<[^>]*>/g, '')
        : null
    },
    resetValues() {
      this.faqEditar.title = null
      this.faqEditar.text = null
      this.promocionSeleccionada = null
      this.categoriaSeleccionada = null
    },
    enviarEditar() {
      this.$refs.form.validate()
      if (this.valid === false) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      this.$emit('editar', this.faqEnviar)
    },
    enviarCrear() {
      this.$refs.form.validate()
      if (this.valid === false) {
        this.addAlert({ text: 'Por favor, revise los campos marcados en rojo', color: 'error' })
        return
      }
      this.$emit('crear', this.faqEnviar)
    }
  },
  watch: {
    promocionSeleccionada(newVal) {
      this.faqEditar.promotion_code = newVal.promotion_code
      this.faqEditar.promotion_desc = newVal.promotion_desc
    }
  }
}
</script>
<style>
.noMostrar .editr--toolbar {
  display: none;
}
.noMostrar .editr {
  border: none;
}
</style>
