import Vue from 'vue'
import Vuex from 'vuex'
import alerts from './alerts'
import login from './login'
import servicios from './servicios'
import ratings from './ratings'
import sales from './sales'
import news from './news'
import products from './products'
import promotions from './promotions'
import surveys from './surveys'
import nuvacoins from './nuvacoins'
import faqs from './faqs'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alerts,
    servicios,
    ratings,
    news,
    promotions,
    nuvacoins,
    sales,
    products,
    surveys,
    login,
    faqs
  }
})
