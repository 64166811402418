import Vue from 'vue'
import VueRouter from 'vue-router'
import beforeEach from './beforeEach'
import login from '@/components/login/login.vue'
import ReestablecerPassword from '@/components/login/ReestablecerPassword.vue'
import ModificarPassword from '@/components/login/ModificarPassword.vue'
import Servicio from '@/pages/Servicio'
import GestionServicios from '@/pages/Admin/GestionServicios'
import GestionNoticias from '@/pages/Admin/GestionNoticias'
import GestionFaqs from '@/pages/Admin/GestionFaqs'
import GestionProductos from '@/pages/Admin/GestionProductos'
import GestionValoraciones from '@/pages/Admin/GestionValoraciones'
import GestionNuvaCoins from '@/pages/Admin/GestionNuvaCoins'
import GestionPromociones from '@/pages/Admin/GestionPromociones'
import Home from '@/pages/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: GestionServicios,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/productos',
    name: 'productos',
    component: GestionProductos,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/valoraciones',
    name: 'valoraciones',
    component: GestionValoraciones,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nuva_coins',
    name: 'nuva_coins',
    component: GestionNuvaCoins,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/promociones',
    name: 'promociones',
    component: GestionPromociones,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: GestionFaqs,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/noticias',
    name: 'noticias',
    component: GestionNoticias,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicio/:servicio',
    name: 'servicio',
    component: Servicio,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/modificar_password/:id/:token',
    name: 'modificarPassword',
    component: ModificarPassword,
    props: true
  },
  {
    path: '/resetearPassword',
    name: 'resetearPassword',
    component: ReestablecerPassword
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(beforeEach) // Comprueba permisos

export default router
