import api from '@/api/api'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getFAQs({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/faqs/get_list_faqs', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async getCategories({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .get('/cms/faqs/get_list_categories', {
            params: obj
          })
          .then(response => {
            if (response.data.ok) {
              resolve(response.data.data)
            } else {
              dispatch('alerts/add', { text: response.data.message, color: 'error' }, { root: true })
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async updateFAQ({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.set('faq', JSON.stringify(obj.faq))
        api
          .post('/cms/faqs/update_faq', formData)
          .then(response => {
            if (response.data.error == null) {
              resolve()
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch('alerts/add', { text: e, color: 'error' }, { root: true })
            reject(e)
          })
      })
    },
    async deleteFAQ({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/cms/faqs/delete_faq', obj)
          .then(response => {
            if (response.data.error == null) {
              resolve()
            } else {
              reject(response.data.error)
            }
          })
          .catch(e => {
            dispatch('alerts/add', { text: e, color: 'error' }, { root: true })
            reject(e)
          })
      })
    }
  },
  getters: {}
}
